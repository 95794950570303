import React, { useContext, useEffect, useRef, useState } from "react";
import { useDocument } from 'react-firebase-hooks/firestore';
import { compose } from "recompose";
import SiteContext, { connectSiteContext } from "../../utils/siteContext";
import * as ROUTES from "../../utils/siteRoutes";
import { withExceptionHandler } from "../../utils/setupErrHandling";
import { auth, firestore, useFirestoreDoc } from "gatsby-theme-firebase";


import DashboardViewComponent from "./DashboardViewComponent";

import MapTileBG from "../../static/img/dashboard/tiles/map.png";
import CalendarTileBG from "../../static/img/dashboard/tiles/schedule.png";
import ApplicationTileBG from "../../static/img/dashboard/tiles/application.png";
import HelpTileBG from "../../static/img/dashboard/tiles/help.png";
import BrandActionBG from "../../static/img/dashboard/tiles/brand-actions.png";

import SDG1 from "../../static/img/dashboard/sdgs/1.jpg";
import SDG2 from "../../static/img/dashboard/sdgs/2.png";
import SDG3 from "../../static/img/dashboard/sdgs/3.jpg";
import SDG4 from "../../static/img/dashboard/sdgs/4.png";
import SDG5 from "../../static/img/dashboard/sdgs/5.png";
import SDG6 from "../../static/img/dashboard/sdgs/6.png";
import SDG7 from "../../static/img/dashboard/sdgs/7.png";
import SDG8 from "../../static/img/dashboard/sdgs/8.png";
import SDG9 from "../../static/img/dashboard/sdgs/9.jpg";
import SDG10 from "../../static/img/dashboard/sdgs/10.png";
import SDG11 from "../../static/img/dashboard/sdgs/11.png";
import SDG12 from "../../static/img/dashboard/sdgs/12.png";
import SDG13 from "../../static/img/dashboard/sdgs/13.png";
import SDG14 from "../../static/img/dashboard/sdgs/14.png";
import SDG15 from "../../static/img/dashboard/sdgs/15.png";
import SDG16 from "../../static/img/dashboard/sdgs/16.png";
import SDG17 from "../../static/img/dashboard/sdgs/17.jpg";

import SDGLogo from "../../static/img/dashboard/tiles/sdgs.png";

const SDGBG = {
  0: SDGLogo,
  1: SDG1,
  2: SDG2,
  3: SDG3,
  4: SDG4,
  5: SDG5,
  6: SDG6,
  7: SDG7,
  8: SDG8,
  9: SDG9,
  10: SDG10,
  11: SDG11,
  12: SDG12,
  13: SDG13,
  14: SDG14,
  15: SDG15,
  16: SDG16,
  17: SDG17
}


const fetchSDG = firebase =>
  () => firebase.firestore.collection('SDGs').doc(firebase.auth.currentUser && firebase.auth.currentUser.uid);

const createSDG = firebase =>
  () => firebase.firestore.collection('users').doc(firebase.auth.currentUser && firebase.auth.currentUser.uid).set({ name: firebase.auth.currentUser.displayName, email: firebase.auth.currentUser.email, sdg: { value: 0, label: 'none' } });

  // Updates a hacker's application with the new information they've provided.
const updateSDG = firebase => updatedSDG => {
  if(firebase.auth.currentUser)
    firebase.firestore.collection('SDGs').doc(firebase.auth.currentUser.uid)
      .update(updatedSDG);
}

// Submit a hacker's application. Non reversible.
const submitApplication = firebase => updatedAppInfo => {
  if(firebase.auth.currentUser)
    firebase.firestore.collection('applications').doc(firebase.auth.currentUser.uid)
      .update({ ...updatedAppInfo, submitted: true, submittedAt: firebase.getTimestamp(new Date()) })
}


const dashboardTiles = {
  schedule: {
    label: " ",
    linkTo: ROUTES.SCHEDULE,
    gridArea: "leftTop",
    backgroundImg: BrandActionBG,
    disabled: true
  },
  sdg: {
    label: "",
    linkTo: "/sdgs",
    gridArea: "leftBot",
    backgroundImg: null,
    color: "white",
    disabled: false
  },
  application: {
    label: "My Application",
    linkTo: ROUTES.APPLICATION,
    gridArea: "centerTop",
    backgroundImg: ApplicationTileBG
  },
  rsvp: {
    label: "Application Status",
    linkTo: ROUTES.APP_STATUS,
    gridArea: "centerTop",
    backgroundImg: ApplicationTileBG
  },
  app_review: {
    label: "Application Review",
    linkTo: ROUTES.APP_REVIEW,
    gridArea: "centerTop",
    backgroundColor: "#e3a368",
    disabled: true
  },
  attendee_list_volunteer: {
    label: "Check In Tool",
    linkTo: ROUTES.ATTENDEE_LIST_VOLUNTEER,
    gridArea: "rightTop",
    backgroundColor: "#66ADEF",
    disabled: true
  },
  attendee_list_organizer: {
    label: "Attendee List",
    linkTo: ROUTES.ATTENDEE_LIST_ORGANIZER,
    gridArea: "rightTop",
    backgroundColor: "#bb7cc1",
    disabled: true
  },
  profile: {
    label: "My Profile",
    linkTo: '/profile',
    gridArea: "rightBot",
    backgroundColor: "#1ec77a"
  },
  judging_tool: {
    label: "Judging Tool",
    linkTo: ROUTES.JUDGING_TOOL,
    gridArea: "centerTop",
    backgroundColor: "#e9c24b",
    disabled: true
  },
  help: {
    label: "Info & Help",
    linkTo: ROUTES.HELP,
    gridArea: "rightTop",
    backgroundImg: HelpTileBG,
    disabled: true
  }
};

const userDashboards = {
  HACKER: [
    dashboardTiles.application,
    dashboardTiles.sdg,
    dashboardTiles.schedule,
    dashboardTiles.help,
    dashboardTiles.profile
  ],
  ORGANIZER: [
    dashboardTiles.app_review,
    dashboardTiles.sdg,
    dashboardTiles.schedule,
    dashboardTiles.attendee_list_organizer,
    dashboardTiles.profile
  ],
  VOLUNTEER: [
    dashboardTiles.attendee_list_volunteer,
    dashboardTiles.sdg,
    dashboardTiles.schedule,
    dashboardTiles.help,
    dashboardTiles.profile
  ],
  JUDGE: [
    dashboardTiles.judging_tool,
    dashboardTiles.sdg,
    dashboardTiles.schedule,
    dashboardTiles.help,
    dashboardTiles.profile
  ],
  GENERAL: [
    dashboardTiles.attendee_list_volunteer,
    dashboardTiles.sdg,
    dashboardTiles.schedule,
    dashboardTiles.help,
    dashboardTiles.profile
  ]
};

const mapContextStateToProps = ({ state: { firebase, dashboardInfo } }) => ({
  logOut: firebase.signOutUser,
  greetingInfo: dashboardInfo.greetingInfo,
  toastInfo: dashboardInfo.toastInfo,
  fetchAppFirestore: fetchSDG(firebase),
  updateAppFirestore: updateSDG(firebase),
  createAppFirestore: createSDG(firebase)
});

const enhance = compose(
  withExceptionHandler,
  connectSiteContext(mapContextStateToProps)
);

export default enhance(({ location, fetchAppFirestore, createAppFirestore, ...props }) => {


  let curUser = auth.currentUser;

  const [ appState, updateAppState ] = useState("FETCHING");
  const [ localAppInfo, updateLocalAppInfo ] = useState({ sdg: { value: 0, label: 'none' } });

  const [data, isLoading, error] = useFirestoreDoc(
    firestore.collection("users").doc(curUser && curUser.uid),
  );

  if (isLoading)
    return null;

  if (error)
    console.log(error);

  let value = data;

  if(value) {
    if(value.exists && ((appState === "FETCHING") || (value.submitted && appState === "SUBMITTING"))) {
      updateLocalAppInfo(value);
      updateAppState("FETCHED");
      if(value.submitted) {
        updateAppState("SUBMITTED");
        // dispatch({ type: "UPDATE_DASHBOARD_TOAST", data: { toastName: "appSubmitted" } });
      }
    } else if(value == null && (appState === "FETCHING")) {
      console.log(value);
      createAppFirestore();
      updateAppState("FETCHED");
    }
  }

  if (curUser && curUser.role === "HACKER" && curUser.submitted) {
    userDashboards.HACKER.shift(); // remove application tile
    userDashboards.HACKER.unshift(dashboardTiles.rsvp); // add rsvp tile
  }

  return (
    <DashboardViewComponent
      name={value}
      curUser={auth.currentUser}
      userDashboards={userDashboards}
      SDGBG={SDGBG[value && value.sdg && value.sdg.value ? value.sdg.value : 0]}
      fetchAppFirestore={fetchAppFirestore}
      {...props}
    />
  );

});
